import axios from "axios";
import config from "@/config";
import localStorageService from "@/services/localStorageService";

let lab = new Object({
  downloadServicesVademecum: () => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/lab/downloadVademecum?token=" +
        encodeURIComponent(token);
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadBarCode: (codes) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/lab/downloadBarCode?codes=" +
        encodeURIComponent(JSON.stringify(codes)) +
        "&token=" +
        encodeURIComponent(token);
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  fetchSamplesLabelTypes: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/lab/fetchSamplesLabelTypes",
        data: {},
        method: "POST",
      })
        .then((res) => {
          resolve({
            labels: res.data.responseData.labels,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSamplesTypes: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/lab/fetchSamplesTypes",
        data: {},
        method: "POST",
      })
        .then((res) => {
          resolve({ types: res.data.responseData.samplesTypes });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPatientSamples: (patient_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/lab/fetchPatientSamples",
        data: { patient_id: patient_id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData.samples);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRelationships: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/lab/fetchRelationships",
        data: {},
        method: "POST",
      })
        .then((res) => {
          resolve({ relationships: res.data.responseData.relationships });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
});

export default lab;

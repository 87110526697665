import Vue from "vue";

import UniversalButton from "@/../../shared/app/components/gui/UniversalButton";
import UniversalDialog from "@/../../shared/app/components/gui/UniversalDialog";
import UniversalDataTable from "@/../../shared/app/components/gui/UniversalDataTable";
import UniversalListAvatar from "@/../../shared/app/components/gui/UniversalListAvatar";

import FormTextInput from "@/../../shared/app/components/gui/FormTextInput";
import FormFileInput from "@/../../shared/app/components/gui/FormFileInput";
import FormSelect from "@/../../shared/app/components/gui/FormSelect";
import FormTextArea from "@/../../shared/app/components/gui/FormTextArea";
import FormCheckBox from "@/../../shared/app/components/gui/FormCheckBox";
import FormAutocomplete from "@/../../shared/app/components/gui/FormAutocomplete";
import FormTextDatePicker from "@/../../shared/app/components/gui/FormTextDatePicker";

import MessageBox from "@/../../shared/app/components/gui/MessageBox";
import SetString from "@/../../shared/app/components/gui/SetString";
import SetStringWithSelect from "@/../../shared/app/components/gui/SetStringWithSelect";
import SetTitleAndColor from "@/../../shared/app/components/gui/SetTitleAndColor";
import SetCurrency from "@/../../shared/app/components/gui/SetCurrency";

Vue.component("UniversalButton", UniversalButton);
Vue.component("UniversalDialog", UniversalDialog);
Vue.component("UniversalDataTable", UniversalDataTable);
Vue.component("UniversalListAvatar", UniversalListAvatar);

Vue.component("FormTextInput", FormTextInput);
Vue.component("FormFileInput", FormFileInput);
Vue.component("FormSelect", FormSelect);
Vue.component("FormTextArea", FormTextArea);
Vue.component("FormCheckBox", FormCheckBox);
Vue.component("FormAutocomplete", FormAutocomplete);
Vue.component("MessageBox", MessageBox);
Vue.component("SetString", SetString);
Vue.component("SetCurrency", SetCurrency);
Vue.component("SetStringWithSelect", SetStringWithSelect);
Vue.component("SetTitleAndColor", SetTitleAndColor);
Vue.component("FormTextDatePicker", FormTextDatePicker);

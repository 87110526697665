import Vue from "vue";

import "@/store/index"; // Force store import to set locale before we instantiate the plugins

import "./vuetify";
import vee from "./vee-validate";
import "./croppie";
import "./filters";
import "./observe";
import "./idle";
import "./bus";
import "./sweetalerts";
import "./gui.js";

import Theme from "./theme";
Vue.use(Theme);

vee.setupLocale();

<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n8">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow mt-8 pt-4 border-radius-xl">
          <div class="position-relative text-center">
            <div class="mb-2" />
            <p
              class="text-sm font-weight-bold mb-2 text-secondary text-border short-text-border d-inline z-index-2 bg-white px-3"
            >
              {{ $t("login-subtitle-2") }}
            </p>
          </div>
          <v-form @submit.prevent="login()" id="login-form">
            <ValidationObserver ref="password_observer" v-slot="{ invalid }">
              <div class="card-padding">
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required|email"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-on:keyup.enter="login()"
                        :disabled="logging"
                        v-model="email"
                        :error-messages="errors"
                        :success="valid"
                        :placeholder="$t('login-email-hint')"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col>
                    <ValidationProvider
                      rules="required"
                      v-slot="{ errors, valid }"
                    >
                      <FormTextInput
                        v-on:keyup.enter="login(false)"
                        :disabled="logging"
                        outlined
                        :error-messages="errors"
                        :success="valid"
                        v-model="password"
                        :placeholder="$t('login-password-hint')"
                        autocomplete="off"
                        :append-icon="password_view ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="() => (password_view = !password_view)"
                        :type="password_view ? 'password' : 'text'"
                      >
                      </FormTextInput>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <div class="text-center">
                  <UniversalButton
                    :disabled="invalid"
                    v-if="!logging"
                    @click="login()"
                  >
                    {{ $t("login-button-credentials") }}
                  </UniversalButton>
                </div>
                <p
                  v-if="logging"
                  class="text-center text-sm text-body mb-0 mt-2"
                >
                  <v-progress-circular
                    class="mb-2"
                    :indeterminate="true"
                    :rotate="0"
                    :size="32"
                    :width="4"
                    color="#3A416F"
                  ></v-progress-circular>
                </p>

                <div class="mt-2" v-if="loginError">
                  <v-alert
                    close-icon="fas fa-times font-size-root text-white"
                    class="alert alert-danger"
                    type="error"
                    dense
                    dismissible
                  >
                    <span class="text-uppercase" v-text="loginError" />
                  </v-alert>
                </div>

                <p
                  v-if="!logging"
                  class="text-center text-sm text-body mt-3 mb-0"
                >
                  {{ $t("login-lost-password-or-first-access") }}<br />

                  <router-link
                    class="text-dark text-decoration-none font-weight-bolder"
                    to="/recovery"
                  >
                    {{ $t("login-lost-password-link-first-access") }}
                  </router-link>
                </p>
              </div>
            </ValidationObserver>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vee from "@/plugins/vee-validate.js";
import staticData from "@/apis/static";

export default {
  data() {
    return {
      logging: false,
      email: "",
      password: "",
      loginError: "",
      password_view: true,
      default_landing_page: "/reports",
    };
  },

  mounted() {
    this.logging = false;
    this.$refs.password_observer.reset();
    window.login = this.$options.methods.login.bind(this);
  },

  computed: {
    getQrCodeStyle() {
      if (this.qrCodeInitInProgress)
        return "margin: auto; margin-top:10px; width:0px; height:0px;";
      else return "margin: auto; margin-top:10px; width:100px; height:100px;";
    },
  },

  methods: {
    parseError(err) {
      if (err.substr && err.substr(0, 3) == "tr-") {
        let tr = this.$t(err.substr(3));
        return tr;
      }

      return err;
    },

    login: function () {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let email = this.email;
      let password = this.password;

      this.loginError = null;
      this.logging = true;
      this.$store
        .dispatch("login", {
          email,
          password,
          timezone,
        })
        .then(() => {
          this.$store
            .dispatch("fetchStaticData")
            .then(() => {
              this.$i18n.locale = this.$store.state.user.locale;
              vee.setupLocale(); // Required to regenerate the locales in the custom messages
              staticData.update();
              // staticData = { ...staticData, ...res.data.responseData.frontend };
              this.$router.push(this.default_landing_page);
            })
            .catch((err) => {
              this.loginError = this.parseError(err);
              this.logging = false;
            });
        })
        .catch((err) => {
          this.loginError = this.parseError(err);
          this.logging = false;
        });
    },
  },
};
</script>

import "./plugins";

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import interceptors from "./services/interceptors";
import store from "./store";

interceptors.setupInterceptors();

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: (h) => h(App),
  onIdle() {},
}).$mount("#app");

import axios from "axios";
import config from "@/config";
import localStorageService from "@/services/localStorageService";

let pricelists = new Object({
  fetchServicePrice: (customer_id, item_id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetchServicePrice",
        data: { customer_id: customer_id, item_id: item_id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchPriceListsPDF: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/pricelist/fetchPriceListsPDF",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  downloadPriceListPDF: (id) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/pricelist/downloadPriceListPDF?id=" +
        encodeURIComponent(id) +
        "&token=" +
        encodeURIComponent(token);
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },

  downloadCompletePriceListPDF: (locale) => {
    return new Promise((resolve /*, reject*/) => {
      const link = document.createElement("a");
      const token = localStorageService.getAccessToken();
      const url =
        config.apiEndPoint +
        "/pricelist/pricelist?locale=" +
        encodeURIComponent(locale) +
        "&complete=1&token=" +
        encodeURIComponent(token);
      link.setAttribute("href", url);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.remove();
      resolve();
    });
  },
});

export default pricelists;

import axios from "axios";
import config from "@/config";
import store from "@/store";
import localStorageService from "@/services/localStorageService";

let users = {
  forceTokenRefresh: () => {
    return new Promise((resolve, reject) => {
      const refreshToken = localStorageService.getRefreshToken();
      axios
        .post(config.apiEndPoint + "/auth/refresh", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.responseData) {
              if (
                res.data.responseData.authToken &&
                res.data.responseData.refreshToken
              ) {
                localStorageService.setToken({
                  access_token: res.data.responseData.authToken,
                  refresh_token: res.data.responseData.refreshToken,
                });
              }
              if (res.data.responseData.notifications) {
                store.state.user.notifications =
                  res.data.responseData.notifications;
                localStorageService.setNotifications(
                  JSON.stringify(store.state.user.notifications)
                );
              }
              resolve();
            }
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateProfile: (user) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/updateProfile",
        data: { user: user },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  uploadAvatar: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/updateAvatar",
        data: { content: content },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchProfileExtraInfo: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/extraInfo",
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTimeZones: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/timeZones",
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changePassword: (password, newPassword) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/changePassword",
        data: { password: password, newPassword: newPassword },
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  recoveryAccount: (email, locale) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/recoveryAccount",
        data: { email: email, locale: locale },
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  resetPassword: (password, token) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/resetPassword",
        data: { password: password, token: token },
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAvatar: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/avatar",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve({ avatar: resp.data.responseData });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  keepAlive: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/keepAlive",
        data: {},
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default users;

import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt from "jsonwebtoken";
import config from "@/config";
import localStorageService from "@/services/localStorageService";
import rightsServices from "@/services/rights";
import avatars from "@/services/avatars";
import usersManager from "@/apis/users";
import i18n from "@/plugins/i18n";
import staticData from "@/apis/static";

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en";

Vue.use(Vuex);
// Reload user information from the cached authToken
let localAuthUser = null;
let localAccessToken = localStorageService.getAccessToken();
let localAvatar = localStorageService.getAvatar();
let localLocale = localStorageService.getLocale();

if (!localLocale) {
  localLocale = getNavigatorLanguage()
    ? getNavigatorLanguage().substr(0, 2)
    : "it";
  i18n.locale = localLocale;
} else {
  i18n.locale = localLocale;
}

if (localAccessToken) {
  let decodedToken = jwt.decode(localAccessToken);
  if (decodedToken) {
    localAuthUser = decodedToken.user;
    localAuthUser.avatar = localAvatar;
    localAuthUser.locale = localLocale;
  }

  i18n.locale = localLocale;
}

let localData = localStorageService.getLocalData();

if (!localData) localData = {};

if (!localAuthUser || !localData) {
  localAuthUser = {
    gravatar: null,
    first_name: "",
    last_name: "",
    business_name: "",
    mobile_phone: "",
    contact_phone: "",
    birth_date: null,
    password_registered: false,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    id: 0,
    avatar: localAvatar,
    email: "",
    locale: getNavigatorLanguage() ? getNavigatorLanguage().substr(0, 2) : "it",
  };
  i18n.locale = localAuthUser.locale;
}

staticData.update();

let context = localStorageService.getContext();
let identity = localStorageService.getIdentity();

export default new Vuex.Store({
  state: {
    global_overlay: null,
    global_overlay_text: null,
    global_overlay_progress: null,
    loggingIn: false,
    user: {
      last_login: localAuthUser.last_login,
      last_login_ip: localAuthUser.last_login_ip,
      first_name: localAuthUser.first_name,
      last_name: localAuthUser.last_name,
      business_name: localAuthUser.business_name,
      timezone: localAuthUser.timezone,
      id: localAuthUser.id,
      email: localAuthUser.email,
      avatar: localAvatar,
      gravatar: localAuthUser.gravatar,
      locale: localAuthUser.locale,
      password_registered: localAuthUser.password_registered,
    },

    frontend: localData.frontend,
    versions: localData.versions,
    context: context,
    identity: identity,

    hasRight: function (right) {
      return rightsServices.hasRight(this.user.rights, right);
    },

    isAdmin: function () {
      return rightsServices.isAdmin(this.user.rights);
    },

    isSuperAdmin: function () {
      return rightsServices.isSuperAdmin(this.user.rights);
    },

    storeContextAndIdentity: function (context, identity) {
      this.context = context;
      this.identity = identity;
      localStorageService.setIdentity(identity);
      localStorageService.setContext(context);
    },
  },

  mutations: {
    loginStart: (state) => {
      state.loggingIn = true;
    },

    loginCompleted: (state, payload) => {
      if (payload.access_token && payload.refresh_token && payload.u) {
        localStorageService.setToken({
          access_token: payload.access_token,
          refresh_token: payload.refresh_token,
        });
        localStorageService.setAvatar(payload.avatar);
        state.user = payload.u;
        state.user.avatar = payload.avatar;

        if (!state.user.locale)
          state.user.locale = getNavigatorLanguage()
            ? getNavigatorLanguage().substr(0, 2)
            : "it";
        localStorageService.setLocale(state.user.locale);
        staticData.update();
      } else {
        state.user = {};
        localStorageService.clearToken();
      }
      state.loggingIn = false;
    },

    logoutCompleted: (state) => {
      state.loggingIn = false;
      state.user = { locale: state.user.locale }; // We keep only the locale
      localStorageService.clearToken();
    },

    staticDataCompleted: (state, payload) => {
      state.frontend = payload.frontend;
      state.locations = payload.locations;
      if (state.user.default_location)
        state.current_location = state.user.default_location;
      if (!state.current_location)
        state.current_location = state.locations[0].id;

      localStorageService.setLocalData(payload);
      localStorageService.setLocation(state.current_location);
    },

    locationChanged: (state, payload) => {
      state.current_location = payload.location;
      localStorageService.setLocation(state.current_location);
    },
  },

  actions: {
    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("loginStart");
        axios({
          url: config.apiEndPoint + "/auth/login",
          data: payload,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.responseError) {
              commit("loginCompleted", { error: resp.data.responseError });
              localStorageService.clearToken();
              reject(resp.data.responseError);
            } else {
              let accessToken = resp.data.responseData.authToken;
              let refreshToken = resp.data.responseData.refreshToken;
              let responseUser = resp.data.responseData.user;
              if (!accessToken || !payload || !refreshToken) {
                commit("loginCompleted", {});
                localStorageService.clearToken();
                if (resp.data.responseError) reject(resp.data.responseError);
                else reject("Invalid response from server");
              } else {
                if (responseUser.gravatar == true) {
                  avatars
                    .fetchGravatar(responseUser.email)
                    .then((result) => {
                      let responseAvatar = result;
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: responseAvatar,
                      });
                      resolve(resp.data.responseData);
                    })
                    .catch((err) => {
                      console.log(err);
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: null,
                      });
                      resolve(resp.data.responseData);
                    });
                } else if (responseUser.gravatar == false) {
                  // Fetch user profile picture
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                  });
                  usersManager
                    .fetchAvatar(responseUser.id, accessToken)
                    .then((result) => {
                      this.state.user.avatar = result.avatar;
                      localStorageService.setAvatar(this.state.user.avatar);
                      resolve(resp.data.responseData);
                    })
                    .catch((err) => {
                      console.log(err);
                      resolve(resp.data.responseData);
                    });
                } else {
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                  });
                  resolve(resp.data.responseData);
                }
              }
            }
          })
          .catch((err) => {
            commit("loginCompleted", { error: err });
            localStorageService.clearToken();
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: config.apiEndPoint + "/auth/logout",
          data: {},
          method: "POST",
        })
          .then(function () {
            commit("logoutCompleted");
            resolve();
          })
          .catch(function (error) {
            commit("logoutCompleted");
            reject(error);
          });
      });
    },

    unauthorized({ commit }) {
      return new Promise((resolve) => {
        commit("logoutCompleted");
        resolve();
      });
    },

    fetchStaticData({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: config.apiEndPoint + "/db/static", method: "POST" })
          .then((resp) => {
            commit("staticDataCompleted", resp.data.responseData);
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
});
